/* :root {
  --zoom-level: 100%;
} */
/* body{
  zoom: var(--zoom-level);
} */

.App {
    text-align: center;
}

.controls {
    margin-bottom: 10px;
}

.image-container {
    position: relative;
    display: inline-block;
}

.point {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    transform: translate(-50%, -50%);
}

.plusZoomButton {
    width: 50px;
    height: 50px;
}

.fixedButtons {
    background-color: "white";
    padding: "20px";
    width: "50px";
    height: "50px";
    display: "flex";
    align-items: "center";
    position: "absolute";
    transform: scale(calc(100% / var(--zoom-level)));
}
